import Link from 'next/link';
import React from 'react';
import Image from 'next/image';

import { SOCAIL } from '@common/constants';
import { cx } from '@emotion/css';
import {
  ACCREDITATIONS,
  BACKED_BY,
  CONTACT,
  HOME_ROUTES,
} from '@util/constants';

import styles from './FooterView.module.scss';

export const FooterView = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <div className={styles.footer_top}>
          <div className={styles.footer_logo}>
            <Link href={HOME_ROUTES.HOME}>
              <Image
                src="/images/header/qeeper-logo.svg"
                alt="qeeper logo icon"
                width={164}
                height={52}
              />
            </Link>
            <div className={styles.contact_footer}>
              <ul className={styles.contact_footer_ul}>
                <li className={cx(styles.cstm_li, styles.menu_item)}>
                  <Link
                    href={`tel:${CONTACT.PHONE}`}
                    target="_blank"
                    className={cx(
                      styles.menu_link,
                      styles.contact_footer_menu_link,
                    )}>
                    <i className={styles.phone_icon}>
                      <Image
                        src="/images/footer/phone.svg"
                        alt="phone icon"
                        width={28}
                        height={28}
                      />
                    </i>
                    {CONTACT.PHONE}
                  </Link>
                </li>
                <li
                  className={cx(
                    styles.cstm_li,
                    styles.saprate,
                    styles.contact_footer_saprate,
                  )}>
                  |
                </li>
                <li className={cx(styles.cstm_li, styles.menu_item)}>
                  <Link
                    href={`mailto:${CONTACT.EMAIL}`}
                    target="_blank"
                    className={cx(
                      styles.menu_link,
                      styles.contact_footer_menu_link,
                    )}>
                    <i className={styles.email_icon}>
                      <Image
                        src="/images/footer/email.svg"
                        alt="request@qeeper.co.uk"
                        width={28}
                        height={28}
                      />
                    </i>
                    {CONTACT.EMAIL}
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className={styles.footer_bottom_menu}>
            <div className={styles.footer_menu}>
              <ul className={styles.footer_links}>
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.ELECTRICAL}
                  title="Electrical"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.PLUMBING}
                  title="Plumbing"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.HEAT_AND_GAS}
                  title="Heat & Gas"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.HANDYMAN}
                  title="Handyman"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.APPLIANCE_REPAIR}
                  title="Appliance Repair"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.LOCKSMITH}
                  title="Locksmith"
                />

                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.LANDLORD_CERTIFICATE}
                  title="Landlord Certificates"
                />

                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.FIRE_RISK_ASSESSMENT}
                  title="Fire Risk Assessment"
                />

                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.CARPET_AND_UPHOLSTERY_CLEANING}
                  title="Carpet & Upholstery Cleaning"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.DRAINAGE}
                  title="Drainage"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.SERVICES.WINDOW_DOOR}
                  title="Window & Door"
                />
                <FooterLink
                  styles={styles}
                  linkHref={HOME_ROUTES.BLOG}
                  title="Blog"
                />
              </ul>
              <div className={styles.footer_buttons}>
                <Link
                  href={HOME_ROUTES.PROPERTY_MANAGER}
                  className={cx(styles.cstm_a, styles.ctm_btn)}>
                  Property Managers
                </Link>
                <Link
                  href={HOME_ROUTES.GET_QUOTE}
                  className={cx(styles.cstm_a, styles.ctm_btn)}>
                  Get Quote
                </Link>
                {/*   <Link
                href={HOME_ROUTES.BOOK_JOB}
                className={cx(
                  styles.cstm_a,
                  styles.ctm_btn,
                  styles.ctm_btn_primary,
                )}>
                Book Job
              </Link> */}
              </div>
            </div>

            <div className={styles.footer_bottom}>
              <div className={styles.footer_accreditations}>
                <p className={styles.footer_accreditations_p}>Accreditations</p>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <Link
                    href={ACCREDITATIONS.GAS_SAFE}
                    target="_blank"
                    className={styles.cstm_a}>
                    <Image
                      src="/images/footer/accreditations-1.svg"
                      alt="Accreditation gas safe icon"
                      className={styles.footer_accreditations_img}
                      width={50}
                      height={50}
                    />
                  </Link>
                  <Link
                    href={ACCREDITATIONS.SAFE}
                    target="_blank"
                    className={styles.cstm_a}>
                    <Image
                      src="/images/footer/accreditations-2.svg"
                      alt="Accreditation ssip icon"
                      className={styles.footer_accreditations_img}
                      width={50}
                      height={50}
                    />
                  </Link>
                  <Link
                    href={ACCREDITATIONS.SAFE}
                    target="_blank"
                    className={styles.cstm_a}>
                    <Image
                      src="/images/footer/accreditations-3.svg"
                      alt="Accreditation safe contractor icon"
                      className={styles.footer_accreditations_img}
                      width={50}
                      height={50}
                    />
                  </Link>
                </div>
              </div>
              <div className={styles.footer_contact}>
                <div className={styles.footer_wrap}>
                  <p className={styles.footer_wrap_p}>{CONTACT.ADDRESS}</p>
                  <div className={styles.contact_wrap}>
                    <ul className={styles.contact_ul}>
                      <li className={styles.cstm_li}>
                        <Link
                          href={`tel:${CONTACT.PHONE}`}
                          className={cx(styles.cstm_a, styles.contact_wrap_a)}>
                          {CONTACT.PHONE}
                        </Link>
                      </li>
                      <li className={styles.cstm_li}>|</li>
                      <li className={styles.cstm_li}>
                        <Link
                          href={`mailto:${CONTACT.EMAIL}`}
                          target="_blank"
                          className={cx(styles.cstm_a, styles.contact_wrap_a)}>
                          {CONTACT.EMAIL}
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className={styles.social_links}>
                    <Link
                      href={SOCAIL.FACEBOOK}
                      target="_blank"
                      className={styles.cstm_a}>
                      <Image
                        src="/images/footer/facebook.svg"
                        alt="facebook-icon"
                        width={37}
                        height={37}
                      />
                    </Link>
                    <Link
                      href={SOCAIL.TWITTER}
                      target="_blank"
                      className={styles.cstm_a}>
                      <Image
                        src="/images/footer/twitter.svg"
                        alt="twitter icon"
                        width={37}
                        height={37}
                      />
                    </Link>
                    <Link
                      href={SOCAIL.INSTAGRAM}
                      target="_blank"
                      className={styles.cstm_a}>
                      <Image
                        src="/images/footer/insta.svg"
                        alt="instagram icon"
                        width={37}
                        height={37}
                      />
                    </Link>
                    <Link
                      href={SOCAIL.LINKEDIN}
                      target="_blank"
                      className={styles.cstm_a}>
                      <Image
                        src="/images/footer/linkd.svg"
                        alt="linkedin icon"
                        width={37}
                        height={37}
                      />
                    </Link>
                  </div>
                  <div
                    className={styles.contact_wrap}
                    style={{ marginTop: 10, marginBottom: -70 }}>
                    <ul className={styles.contact_ul}>
                      <li className={styles.cstm_li}>
                        <Link
                          href={HOME_ROUTES.ABOUT_US}
                          className={cx(styles.cstm_a, styles.contact_wrap_a)}>
                          About Us
                        </Link>
                      </li>
                      <li className={styles.cstm_li}>|</li>
                      <li className={styles.cstm_li}>
                        <Link
                          href={HOME_ROUTES.CONTACT_US}
                          className={cx(styles.cstm_a, styles.contact_wrap_a)}>
                          Contact Us
                        </Link>
                      </li>
                      <li className={styles.cstm_li}>|</li>
                      <li className={styles.cstm_li}>
                        <Link
                          href={HOME_ROUTES.TERMS_CONDITIONS}
                          className={cx(styles.cstm_a, styles.contact_wrap_a)}>
                          Terms & Conditions
                        </Link>
                      </li>
                      <li className={styles.cstm_li}>|</li>
                      <li className={styles.cstm_li}>
                        <Link
                          href={HOME_ROUTES.PRIVACY_POLICY}
                          className={cx(styles.cstm_a, styles.contact_wrap_a)}>
                          Privacy Policy
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={styles.footer_backed}>
                <p className={styles.footer_backed_p}>PROUD TO BE BACKED BY</p>
                <div className={styles.footer_cp_img}>
                  <Link
                    href={BACKED_BY.SFC}
                    target="_blank"
                    className={styles.cstm_a}>
                    <Image
                      src="/images/footer/backed1.svg"
                      alt="Backed by sfc icon"
                      width={50}
                      className={styles.footer_backed_img}
                      height={50}
                    />
                  </Link>
                  <Link
                    href={BACKED_BY.ANTLER}
                    target="_blank"
                    className={styles.cstm_a}>
                    <Image
                      src="/images/footer/backed2.svg"
                      alt="Backed by antler icon"
                      className={styles.footer_backed_img}
                      width={50}
                      height={50}
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

type FooterLinkProps = {
  styles: any;
  title: string;
  linkHref: string;
};
const FooterLink = ({ styles, title, linkHref }: FooterLinkProps) => (
  <li className={cx(styles.cstm_li, styles.footer_li, styles.menu_item)}>
    <Link
      href={linkHref}
      className={cx(styles.cstm_a, styles.menus_link, styles.footer_links_a)}>
      {' '}
      {title}
    </Link>
  </li>
);
