import '../public/styles/global.css';

import { DefaultSeo } from 'next-seo';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';
import { Plus_Jakarta_Sans, DM_Serif_Display } from 'next/font/google';
import { cx } from '@emotion/css';

import { LayoutView } from '@modules/layout/LayoutView';

import * as gtag from '../lib/gtag';
import SEO from '../next-seo.config.js';

const jakartaPlusSans = Plus_Jakarta_Sans({
  display: 'swap',
  subsets: ['latin'],
  weight: ['200', '300', '400', '500', '600', '700', '800'],
});

const dmSerifDisplay = DM_Serif_Display({
  weight: ['400'],
  display: 'swap',
  subsets: ['latin'],
  style: ['italic', 'normal'],
});

const App = ({ Component, pageProps }: any) => {
  const router = useRouter();
  const queryClientRef = useRef<QueryClient>();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageview(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (queryClientRef.current) {
      const localStoragePersistor = createWebStoragePersistor({
        storage: window.localStorage,
      });
      persistQueryClient({
        queryClient: queryClientRef.current,
        persistor: localStoragePersistor,
      });
    }
  }, []);

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          cacheTime: 1000 * 60 * 60 * 24, // 24 hours
        },
      },
    });
  }

  return (
    <>
      <Head>
        <link rel="shortcut icon" href="/static/qeeper-favicon.png" />
      </Head>
      {/* Global Site Tag (gtag.js) - Google Analytics */}
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <DefaultSeo {...SEO} />
      <QueryClientProvider client={queryClientRef.current}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Hydrate state={pageProps.dehydratedState}>
          <LayoutView>
            <div
              className={cx(
                jakartaPlusSans.className,
                dmSerifDisplay.className,
              )}>
              <Component {...pageProps} />
            </div>
          </LayoutView>
        </Hydrate>
      </QueryClientProvider>
    </>
  );
};

export default App;
